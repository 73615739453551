import React, { Component } from "react"
import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from "../config"

import Layout from "../components/layout"
import CookieService from '../services/CookieService'
import SEO from "../components/seo"
import loadingIcon from "../images/loader.svg"
import AlbumService from "../services/AlbumService"
import UserService from "../services/UserService"
import BucketService from "../services/BucketService"
import Image from "../components/image"
import AlbumEditForm from "../components/album-edit-form"
import Portal from '../components/portal'

import assetDeleteIcon from '../images/asset-delete.png'
import editIcon from "../images/edit.png"
import pendingIcon from "../images/in-progress.png"

import './albums.css'

Amplify.configure(config)

class PeoplePage extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false

    this.state = {
      loading: true,
      people: [],
      selectedAlbum: null
    }

    this.updateAlbum = this.updateAlbum.bind(this)
  }

  async componentDidMount () {
    this._isMounted = true
    this.userInfo = await UserService.getUserInfo()

    const cookies = await CookieService.set() // eslint-disable-line no-unused-vars
    const albumList = await AlbumService.getAlbums()

    const data = albumList.filter(album => (album.type === 'PEOPLE'))

    if (this._isMounted) {
      this.setState({
        loading: false,
        people: data
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async updateAlbum(alBumData) {
    const albumList = await AlbumService.updateAlbumInfo(alBumData)
    const data = albumList.filter(album => (album.type === 'PEOPLE'))

    if (this._isMounted) {
      this.setState({
        loading: false,
        people: data,
        selectedAlbum: null
      })
    }
  }

  async deleteAction(albumId) {
    if (!albumId) return
    const conf = window.confirm(
      "ATTENTION - z'êtes vraiment vraiment sur de vouloir effacer cet album? (les photos seront toujours disponibles mais l'information de groupage sera non récuperable."
    )
    if (conf) {
      const res = await AlbumService.deleteAlbum(albumId)
      if (!res) {
        alert("there was a pb")
      } else {
        const albumList = await AlbumService.getAlbums()
        const data = albumList.filter(album => (album.type === 'PEOPLE' && album.albumId !== albumId))

        if (this._isMounted) {
          this.setState({
            loading: false,
            people: data,
          })
        }
      }
    }
  }

  getAlbumBtns (album) {
    let btns = []
    if (album.albumId.split('|')[0] === this.userInfo.username) { // my album
      // edit button
      btns.push(<div key='btn-edit' className='album-btn'>
        <button onClick={() => this.setState({ selectedAlbum: album })}><img src={editIcon} alt="Effacer l'album"></img></button>
      </div>)
      // delete button
      btns.push(<div key='btn-del' className='album-btn'>
        <button onClick={() => this.deleteAction(album.albumId)}><img src={assetDeleteIcon} alt="Effacer l'album"></img></button>
      </div>)
    }
    return btns
  }

  getRenderContent () {
    if (this.state.loading) {
      return <div className="loader" style={{ margin: '10rem' }}><img src={loadingIcon} alt="loading" /></div>
    }

    const content = []

    if (this.state.people.length) {
      const peopleContent = []
      this.state.people.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      }).forEach((album, index) => {

        peopleContent.push(
          <div className="album-block-wrapper" key={`people_${index}`}>
            <div className="image-polaroid">
              <a
                key={`wrap_${index}`}
                href={`/album/${album.albumId}`}
                title={album.name}
                id={`Day${album.albumId}`}
              >
                <div className="album-block">
                  <div className="album-block-image">
                   {album.primeMedia.key ? <Image data={album.primeMedia} /> : <img src={pendingIcon} style={{ maxWidth: '100px' }} alt='pending data' />}
                  </div>
                  <div className="album-block-content">
                    {(album.private !== '') ? 
                      <><div style={{ fontSize: '1.125rem', fontWeight: 500, margin: '.25rem 0 0', color: '#aaaaaa' }}>{album.name}</div><div style={{ fontStyle: 'italic', margin: '.125rem 0', color: '#aaaaaa', fontSize: '.9rem' }}>({BucketService.getBucketInfo(album.private).display})</div></> : 
                      <div style={{ fontSize: '1.125rem', fontWeight: 500, margin: '.125rem 0' }}> {album.name}</div>
                    }
                    <div style={{ fontSize: '.9rem', lineHeight: 1.125 }}>{album.description}</div>
                  </div>
                  {album.count ? <div className="album-media-count"><span className="album-media-count-inner">{album.count} médias</span></div> : ''}
                </div>
              </a>
              <div 
                key={`btns_${index}`}
                className="album-btns"
              >
                {this.getAlbumBtns(album)}
              </div>
            </div>
          </div>)

        }
      )
      content.push( <div key='type-people'>
          <div className="album-wrapper">
          {peopleContent}
          </div>
        </div> )
    }

    return content
  }


  render () {
    let portalContent = ''
    if (this.state.selectedAlbum) {
      portalContent = <Portal>
        <div className="modal">
          <div className="modal-background"></div>
            <div className="modal-content new-album">
              <AlbumEditForm 
                selectedAlbum={this.state.selectedAlbum}
                updateAlbum={this.updateAlbum} />
              <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({ selectedAlbum: null })}></button>
            </div>
          </div>
        </Portal>
    }

    return (
      <Layout menu="PEOPLE">
        <SEO title="Albums" />
        <h1>Participants</h1>
        {/* <div style={{ margin: '10rem auto', maxWidth: '150px' }}><img src={inProgressIcon} alt='Travail en cours' /></div> */}
        {this.getRenderContent()}
        {portalContent}
      </Layout>
      )
  }
}

export default withAuthenticator(PeoplePage, {
  includeGreetings: false
 })