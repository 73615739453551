import React, { Component } from "react"
import AlbumService from '../services/AlbumService'
import DisplayService from '../services/DisplayService'
import './album-form.css'

import Flatpickr from 'react-flatpickr'
import '../../node_modules/flatpickr/dist/flatpickr.css'

class AlbumEditForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      description: '',
      dob: null,
      dobDisabled: true,
      dod: null,
      dodDisabled: true,
      errors: {
        dob: '',
        dod: ''
      }
    }
  }

  componentDidMount() {
    this.setState({
      description: this.props.selectedAlbum.description,
      dob: this.props.selectedAlbum.data.dob,
      dobDisabled: !this.props.selectedAlbum.data.dob,
      dod: this.props.selectedAlbum.data.dod,
      dodDisabled: !this.props.selectedAlbum.data.dod,
    })
  }

  async updateAlbum() {
    if (this.props.selectedAlbum.type === 'PEOPLE') {
      this.props.selectedAlbum.data.dob = this.state.dobDisabled ? null : new Date(this.state.dob).setHours(12)
      this.props.selectedAlbum.data.dod = this.state.dodDisabled ? null : new Date(this.state.dod).setHours(12)
    }
    return await this.props.updateAlbum({ 
      ...this.props.selectedAlbum,
      description: this.state.description
     })
  }

  async updateDOB(selectedDates) {
    await this.validate({
      dob: selectedDates.length ? selectedDates[0] : null
    })
  }

  async updateDOD(selectedDates) {
    await this.validate({
      dod: selectedDates.length ? selectedDates[0] : null
    })
  }

  async inputDOB(DOBStatus) {
    await this.validate({
      dob: DOBStatus ? null : this.state.dob,
      dobDisabled: DOBStatus 
    })
  }

  async inputDOD(DODStatus) {
    await this.validate({
      dod: DODStatus ? null : this.state.dod,
      dodDisabled: DODStatus 
    })
  }

  async validate(updateData) {
    let state = { ...this.state, ...updateData }
    const errors = {
      dob: '',
      dod: ''
    }

    if (this.props.selectedAlbum.type === 'PEOPLE') {
      if (!state.dobDisabled && !state.dob) errors.dob = 'Date non-valide'
      if (!state.dodDisabled && !state.dod) errors.dod = 'Date non-valide'
    }

    this.setState({ ... state, errors })

    let errorState = false
    Object.keys(errors).forEach(key => {
      if (errors[key]) errorState = true
    })

    return !errorState
  }

  render() {
    let type = AlbumService.getAlbumTypes().filter(type => type.code === this.props.selectedAlbum.type)
    type = type.length ? type[0].name : '' 

    let dateDetail = []
    if (this.props.selectedAlbum.type === 'EVENT') {
      let dateString = this.props.selectedAlbum.startDate !== this.props.selectedAlbum.endDate ? 
        `${DisplayService.getFormattedDate(this.props.selectedAlbum.startDate)} - ${DisplayService.getFormattedDate(this.props.selectedAlbum.endDate)}` :
        DisplayService.getFormattedDate(this.props.selectedAlbum.startDate)
      
      dateDetail.push(<div className="field" key='field_date'>
          <label className="label new-album-label">Date(s)</label>
          <div className="control">
            <input type="text" className='input no-select' value={dateString} disabled></input>
          </div>
        </div>)
    }

    if (this.props.selectedAlbum.type === 'PEOPLE') {
      let today = new Date();
      /* Date of Birth */
      let dobInput = <input key="field_dob" className="input" placeholder="Date de naissance" disabled />
      if (!this.state.dobDisabled) {
        dobInput = <Flatpickr 
            key="field_dob"
            onChange={selectedDates => this.updateDOB(selectedDates)} 
            className='input'
            placeholder="Date de naissance"
            options={{
              maxDate: today,
              defaultDate: this.state.dob,
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
            }}
            maxdate={today}
            dateformat='J M Y'
          />
      }
      /* Date of Decease */
      let dodInput = <input key="field_dod" className="input" placeholder="Date de décès" disabled />
      if (!this.state.dodDisabled) {
        dodInput = <Flatpickr 
            key="field_dod"
            onChange={selectedDates => this.updateDOD(selectedDates)} 
            className='input'
            placeholder="Date de décès"
            options={{
              maxDate: today,
              defaultDate: this.state.dod,
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
            }}
            maxdate={today}
            dateformat='J M Y'
          />
      }


      dateDetail.push(<div className="field" key='field_dob'>
          <label className="label new-album-label">Date de Naissance</label>
          <div className="control people-date-control">
            <div className="people-date-control-wrap">
              <input type="checkbox" onChange={(evt) => this.inputDOB(!evt.target.checked)} className="people-date-control-checkbox" key="field_dob_disabled" id="field_dob_disabled" checked={!this.state.dobDisabled} />
              <label htmlFor="field_dob_disabled" className="people-date-control-label"> </label>
            </div>
            {dobInput}
          </div>
            <p className="help is-danger">{this.state.errors.dob}</p>
        </div>)
      dateDetail.push(<div className="field" key='field_dod'>
        <label className="label new-album-label">Date de Décès</label>
        <div className="control people-date-control">
          <div className="people-date-control-wrap">
            <input type="checkbox" onChange={(evt) => this.inputDOD(!evt.target.checked)} className="people-date-control-checkbox" key="field_dod_disabled" id="field_dod_disabled" checked={!this.state.dodDisabled} />
            <label htmlFor="field_dod_disabled" className="people-date-control-label"></label>
          </div>
          {dodInput}
        </div>
          <p className="help is-danger">{this.state.errors.dod}</p>
      </div>)      
    }

    return (
      <>
        <h2 key='field_title'>Détail d'album</h2>

        <div className="field" key='field_type'>
          <label className="label new-album-label">Type</label>
          <div className="control">
            <input type="text" className='input no-select' value={type} disabled></input>
          </div>
        </div>

        <div className="field" key='field_name'>
          <label className="label new-album-label">Nom</label>
          <div className="control ">
            <input type="text" className='input' value={this.props.selectedAlbum.name} disabled></input>
          </div>
        </div>

        {dateDetail}

        <div className="field" key='field_description'>
          <label className="label new-album-label">Description</label>
          <div className="control">
            <textarea className="textarea" placeholder="Description" onChange={(evt) => this.setState({ description: evt.target.value })} value={this.state.description}></textarea>
          </div>
        </div>

        <div className="field" key='field_submit'>
          <div className="control">
            <button className="button" onClick={async () => await this.updateAlbum()}>Mettre à jour</button>
          </div>
        </div>
      </>
    )
  }
}

export default AlbumEditForm
