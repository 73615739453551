import AppStore from "../services/AppStore"
import ApiService from './ApiService'
import UserService from './UserService'
import DisplayService from './DisplayService'
import eventIcon from '../images/menu/menu_calendar.png'
import peopleIcon from '../images/menu/menu_user.png'
// import locationIcon from '../images/menu/menu_location.png'
import otherIcon from '../images/menu/menu_watch.png'

const AlbumService = (() => {
    const getAlbumTypes = () => {
        return [
            { code: 'EVENT', name: 'Evènementiels', icon: eventIcon },
            { code: 'PEOPLE', name: 'Participants', icon: peopleIcon },
            { code: 'OTHER', name: 'Thématiques', icon: otherIcon }
        ]
    }

    const getAlbumId = async (albumInfo) => {
      let userInfo = await UserService.getUserInfo()
      let parts = [userInfo.username, albumInfo.selectedType]
      let error = false
      switch(albumInfo.selectedType) {
        case 'EVENT':
          if (albumInfo.selectedDates.length === 2) {
            parts.push(`${DisplayService.toYYYYMMDD(albumInfo.selectedDates[0])}${DisplayService.toYYYYMMDD(albumInfo.selectedDates[1])}${albumInfo.name}`)
          } else {
            error = true
          }
          break;
        default:
          parts.push(albumInfo.name)
      }
      if (error) return ''
      return parts.join('|')
    }

    const extractIdData = (albumId) => {
      const split = albumId.split('|')
      let data = { user: split[0] }
      data.name = split[2]
      if (split[1] === 'EVENT') {
        data.startDate = DisplayService.fromYYYYMMDD(split[2].substr(0, 8))
        data.endDate = DisplayService.fromYYYYMMDD(split[2].substr(8, 8))
        data.name = split[2].substr(16)
      }
      return {
        type: split[1],
        user: split[0],
        ...data
      }
    }

    const createAlbumInfo = async (albumInfo) => {
        /*
        const albumInfo = {
            name: '',
            description: '',
            type: '',
            allow_edit: false,
            published: false,
            private: true,
            selectedDates: [],
            dob: null,
            dod: null   
          }
         */

        let userInfo = await UserService.getUserInfo()

        let privateStr = '[NONE]'
        if (albumInfo.private) {
          if (userInfo.attributes['custom:bucket-name']) privateStr = userInfo.attributes['custom:bucket-name']
          else privateStr = userInfo.username
        }

        let data = {}
        if (albumInfo.selectedType === 'PEOPLE') {
          data = {
            dob: albumInfo.dob,
            dod: albumInfo.dod
          }
        }
        const body = {
          albumId: await getAlbumId(albumInfo), // userName | eventType | albumName
          name: albumInfo.name,
          description: !albumInfo.description ? '[NONE]' : albumInfo.description,
          type: albumInfo.selectedType,
          allow_edit: false,
          published: false,
          private: privateStr,
          count: 0,
          primeMedia: '[]',
          peoples: '[]',
          data: JSON.stringify([data])
        }

        let albumList = await getAlbums()
        const albumIdData = extractIdData(body.albumId)
        albumList.push({
          ...body,
          primeMedia: [],
          peoples: [],          
          description: body.description === '[NONE]' ? '' : body.description,
          private: body.private === '[NONE]' ? '' : body.private,
          ...albumIdData,
          dob: albumInfo.dob,
          dod: albumInfo.dod
        })
        AppStore.set("ALBUMS", albumList)
        await ApiService.upsertAlbumInfo(body) // TO TEST no need to wait? :)
        return { albumList, newAlbumId: body.albumId }
    }

    const updateAlbumInfo = async (albumData) => {
      /*
      const albumData = {
        albumId: '',
        name: '',
        description: '',
        type: '',
        allow_edit: false,
        published: false,
        private: '',
        user: '',
        startDate: new Date(),
        endDate: new Date(),
        peoples: [],
        primeMedia: {},
        count: 0,
        dob: null,
        dod: null
      }
      */
     console.log('**updateAlbumInfo** >> albumData', albumData)

      let albumList = await getAlbums()

      albumList.forEach((album, index) => {
        if (album.albumId === albumData.albumId) {
          albumList[index].description = albumData.description
          albumList[index].data = {
            dob: albumData.data.dob,
            dod: albumData.data.dod
          }
        }
      })

      AppStore.set("ALBUMS", albumList)

      await ApiService.upsertAlbumInfo({
        albumId: albumData.albumId,
        name: albumData.name,
        description: !albumData.description ? '[NONE]' : albumData.description,
        type: albumData.type,
        allow_edit: albumData.allow_edit,
        published: albumData.allow_edit,
        private: !albumData.private ? '[NONE]' : albumData.private,
        peoples: JSON.stringify(albumData.peoples),
        primeMedia: JSON.stringify([{ 
          key_d: albumData.primeMedia.key, 
          width_d: albumData.primeMedia.width, 
          height_d: albumData.primeMedia.height,
        size_d: albumData.primeMedia.size
        }]),
        data: JSON.stringify([{
          dob: albumData.data.dob,
          dod: albumData.data.dod
        }]),
        count: albumData.count
     })

     return albumList
    }

    const upsertAlbumSortData = async(albumId, sortDataArray) => {
      AppStore.set(`ALBUMSORT_${albumId}`, sortDataArray)
      await ApiService.upsertAlbumSortData({
        albumId,
        sort: JSON.stringify(sortDataArray)
      })
    }


    const addAlbumItem = async (albumMediaInfo, selectedAlbum) => {
        /*
        const albumMediaInfo = {
            "alb": "Cedric%7CEVENT%7CTest%20Event",
            "day": 20030602,
            "height": 2281,
            "key": "the-red-frog-cedric-alicia/public/images/2003/20030602-18.jpg",
            "size": 1875189,
            "width": 3200
          }
        */
       
        const keySplits = albumMediaInfo.key.split('/')
        const day = keySplits[keySplits.length - 1].substr(0, 8)
        const body = {
          alb: selectedAlbum,
          day,
          height: albumMediaInfo.height,
          key: albumMediaInfo.key,
          size: albumMediaInfo.size,
          width: albumMediaInfo.width       
        }

        let dayAlbumItems = await getDayAlbumItems(day)
        dayAlbumItems.push({
          albumId: selectedAlbum,
          height: albumMediaInfo.height,
          key: albumMediaInfo.key,
          size: albumMediaInfo.size,
          width: albumMediaInfo.width              
        })
        AppStore.set(`DAYALBUMITEMS_${day}`, dayAlbumItems)
        AppStore.unset(`ALBUMITEMS_${selectedAlbum}`) // to make sure it is reloaded if nedded later on

        await ApiService.upsertAlbumMediaInfo(body) // TO TEST no need to wait? :)

        return dayAlbumItems
    }

    const bulkAddAlbumItem = async (albumMediaInfos, selectedAlbum) => {
      /*
      const albumMediaInfos = [{
          "alb": "Cedric%7CEVENT%7CTest%20Event",
          "day": 20030602,
          "height": 2281,
          "key": "the-red-frog-cedric-alicia/public/images/2003/20030602-18.jpg",
          "size": 1875189,
          "width": 3200
        }]
      */
      const days = []
      const dataList = albumMediaInfos.map(item => {
        const keySplits = item.key.split('/')
        const day = keySplits[keySplits.length - 1].substr(0, 8)
        if (days.indexOf(day) < 0) days.push(day)
        return {
          alb: selectedAlbum,
          day,
          height: item.height,
          key: item.key,
          size: item.size,
          width: item.width  
        }
      })
      const dayAlbumItemsDays = []
      let dayAlbumItems = []
      for (let d = 0; d < days.length; d++) {
        dayAlbumItems = await getDayAlbumItems(days[d])
        for (let i = 0; i < dataList.length; i++) {
          if (days[d] - dataList[i].day !== 0) return
          dayAlbumItems.push({
            albumId: selectedAlbum,
            height: dataList[i].height,
            key: dataList[i].key,
            size: dataList[i].size,
            width: dataList[i].width              
          })
        }
        dayAlbumItemsDays.push({ day: days[d], dayAlbumItems})
        AppStore.set(`DAYALBUMITEMS_${days[d]}`, dayAlbumItems)
        AppStore.unset(`ALBUMITEMS_${selectedAlbum}`) // to make sure it is reloaded if nedded later on
      }

      await ApiService.bulkUpsertAlbumMediaInfo(dataList) // TO TEST no need to wait? :)

      return dayAlbumItemsDays
  }

    const removeAlbumItem = async (mediaKey, selectedAlbum) => {
      const keySplits = mediaKey.split('/')
      const day = keySplits[keySplits.length - 1].substr(0, 8)
      
      let dayAlbumItems = await getDayAlbumItems(day)
      dayAlbumItems = dayAlbumItems.filter(item => (item.key !== mediaKey))
      AppStore.set(`DAYALBUMITEMS_${day}`, dayAlbumItems)
      
      let albumItems = await getAlbumItems(selectedAlbum)
      albumItems = albumItems.filter(item => (item.key !== mediaKey))
      AppStore.set(`ALBUMITEMS_${selectedAlbum}`, albumItems)

      await ApiService.deleteAlbumMediaInfo(selectedAlbum, mediaKey) // TO TEST no need to wait? :)
      return {
        albumItems,
        dayAlbumItems
      }
    }

    const parseprimeMediaStr = (string) => {
      let result = {key: '', width: 0, size: 0, height: 0}
      if (string) {
        const resultTmp = JSON.parse(string)
        if (resultTmp.length) {
          result.key = resultTmp[0].key_d
          result.width = resultTmp[0].width_d
          result.height = resultTmp[0].height_d
          result.size = resultTmp[0].size_d
        }
      }
      return result
    }

    const parseDataString = (string) => {
      let result = { 
        dob: null,
        dod: null
      }
      if (string) {
        const resultTmp = JSON.parse(string)
        if (resultTmp.length) {
          result.dob = resultTmp[0].dob
          result.dod = resultTmp[0].dod
        }
      }
      return result;
    }

    const getAlbums = async () => {
        let albums = AppStore.get("ALBUMS")
        if (!albums) {
            const albumsData = await ApiService.getAlbums()
            albums = albumsData.map(album => {
              const albumData = extractIdData(album.albumId)
              return {
                ...album,
                description: album.description === '[NONE]' ? '' : album.description,
                private: album.private === '[NONE]' ? '' : album.private,
                peoples: album.peoples ? JSON.parse(album.peoples) : [],
                primeMedia: parseprimeMediaStr(album.primeMedia),
                data: parseDataString(album.data),
                count: album.count,
                ...albumData
              }
            })
            AppStore.set("ALBUMS", albums)
        } else {
          // we need to re-init the dates as they are still stringified
          albums = albums.map(album => ({
            ...album,
            startDate: new Date(album.startDate),
            endDate: new Date(album.endDate)
          }))
        
        }
        return albums
    }

    const getAlbumSortData = async (albumId) => {
      let albumSortData = AppStore.get(`ALBUMSORT_${albumId}`)
      if (!albumSortData) {
        const data = await ApiService.getAlbumSortData(albumId)
        albumSortData = JSON.parse(data)
      }
      return albumSortData
    }

    const getAlbumItems = async (albumId) => {
        let albumItems = AppStore.get(`ALBUMITEMS_${albumId}`)
        if (!albumItems) {
            albumItems = await ApiService.getAlbumMedias(albumId)
            AppStore.set(`ALBUMITEMS_${albumId}`, albumItems)
        }
        return albumItems
    }

    const getSortedAlbumItems = async (albumId, ignoreSortData) => {
      const albumItems = await getAlbumItems(albumId)
      const albumSortData = ignoreSortData? [] : await getAlbumSortData(albumId)
      const albumIdInfo = extractIdData(albumId)
      let data = []

      // use chronological order descendent
      if (albumIdInfo.type === "PEOPLE" || albumIdInfo.type === "OTHER") {
        data = albumItems
          .map(album => {
            const split = album.key.split("/")
            return {
              ...album,
              sortKey: split[split.length - 1],
              mode: "ALBUM",
            }
          })
          .sort((a, b) => {
            if (albumSortData && albumSortData.indexOf(a.key) < albumSortData.indexOf(b.key)) return -1
            if (albumSortData && albumSortData.indexOf(a.key) > albumSortData.indexOf(b.key)) return 1

            if (a.sortKey < b.sortKey) return 1
            if (a.sortKey > b.sortKey) return -1
            return 0
          })
      }

      // use chronological order ascendent
      if (albumIdInfo.type === "EVENT") {
        data = albumItems
          .map(album => {
            const split = album.key.split("/")
            return {
              ...album,
              sortKey: split[split.length - 1],
              mode: "ALBUM",
            }
          })
          .sort((a, b) => {
            if (albumSortData && albumSortData.indexOf(a.key) < albumSortData.indexOf(b.key)) return -1
            if (albumSortData && albumSortData.indexOf(a.key) > albumSortData.indexOf(b.key)) return 1

            if (a.sortKey < b.sortKey) return -1
            if (a.sortKey > b.sortKey) return 1
            return 0
          })
      }

      return data
    }

    const getDayAlbumItems = async (day) => {
        let albumItems = AppStore.get(`DAYALBUMITEMS_${day}`)
        if (!albumItems) {
            albumItems = await ApiService.getDayAlbumMedias(day)
            AppStore.set(`DAYALBUMITEMS_${day}`, albumItems)
        }
        return albumItems
    }

    const deleteAlbum = async (albumId) => {
      // unset album data
      // unset album list data? (or should I update?)
      AppStore.unset("ALBUMS")
      AppStore.unset(`ALBUMITEMS_${albumId}`)
      
      return await ApiService.deleteAlbum(albumId)
    }

    return {
      extractIdData,
      getAlbumTypes,
      getAlbumId,
      createAlbumInfo,
      updateAlbumInfo,
      upsertAlbumSortData,
      removeAlbumItem,
      addAlbumItem,
      bulkAddAlbumItem,
      getAlbums,
      getAlbumSortData,
      getAlbumItems,
      getSortedAlbumItems,
      getDayAlbumItems,
      deleteAlbum
    }
})()

export default AlbumService
